import Header from '../components/Header'
import Footer from '../components/Footer'
import Head from '../components/Head'
import * as common from "../common/commonFunction"
import commonSession from '../common/commonSession';
import { isMobile } from "react-device-detect";
import { useState } from 'react';


const Login = () => {
    const [enterprise,setEnterprise] = useState(false)
    // login session info 
    var sessionInfo = commonSession.getSession();
    var userId = sessionInfo.userId;
    var token = sessionInfo.token;


    const activeTab = (e) => {
        $('.join-box').find('.join-btn').removeClass('active');
        $(e.target).addClass('active');
s
        var type = $(e.target).attr('data-id');
        setEnterprise(false);

        if(type=='corp'){
            setEnterprise(true);
        }

        $('.join-link-tag').find('a').css('display','none');
        $('.join-link-tag').find('a.'+type).css('display','block');
    }

    const togglePwIcon = (e) => {
        if($('.fa-eye').hasClass('d-none')){
            $('.fa-eye').removeClass('d-none')
            $('.fa-eye-slash').addClass('d-none')
            $('#pw').attr('type','text');
        }else{
            $('.fa-eye').addClass('d-none')
            $('.fa-eye-slash').removeClass('d-none')
            $('#pw').attr('type','password');
        }
    }

    return (
        <>
            <Head />
            <Header />
            <link rel='stylesheet' href='/css/login.min.css' />
            <div className="container" onClick={() => common.focusOutMenu()}>
                <content>
                    <div className="input-form">
                        <div class="join-box tab-area">
                            <button class="join-btn active" data-id="normal" type="button" onClick={activeTab}>일반 회원</button>
                            <button class="join-btn" data-id="corp" type="button" onClick={activeTab}>기업 회원</button>
                        </div>
                        <form className="login-form" noValidate>
                            <div className="mb-5">
                                <label htmlFor="userEmail" className="form-label">이메일</label>
                                <input type="text" className="form-control box" id="userEmail" placeholder="User Email" required
                                    onKeyPress={(e) => { if (e.key === 'Enter') document.getElementById('pw').focus(); }}
                                />
                                <div className="invalid-feedback">
                                    올바른 이메일을 입력해 주세요.
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">비밀번호</label>
                                <input type="password" className="form-control box" id="pw" placeholder="Password" minLength="6" required
                                    onKeyPress={(e) => { if (e.key === 'Enter') { common.loginFunc() } }}
                                />
                                <div className="password-icon" onClick={togglePwIcon}>
                                    <i class="fa-solid fa-eye d-none"></i>
                                    <i class="fa-solid fa-eye-slash"></i>
                                </div>
                                <div className="invalid-feedback">
                                    올바른 비밀번호를 입력해 주세요.
                                </div>
                            </div>
                            <div className="btn-box d-flex align-items-center justify-content-between">
                                <div className="save-id">
                                    <label htmlFor="save-id">
                                        <input type="checkbox" name="save_id" id="save-id"></input>
                                        <label htmlFor='save-id'></label>
                                        <span className="label-text">아이디 저장</span>
                                    </label>
                                </div>
                                <p className="text-start find-password">
                                    <a href="/findPw">비밀번호 찾기</a>
                                </p>
                            </div>
                            <button className="login-btn btn btn-primary btn-lg basicBtn" id="loginBtn" type="button" onClick={() => common.loginFunc()}>로그인</button>
                            <div className="join-link-box d-flex align-items-center justify-content-between">
                                <p className="text-start label-join-link">
                                    아직 회원이 아니신가요?
                                </p>
                                <p className="text-right join-link-tag">
                                    <a href="/registration"     class="normal">회원가입</a>
                                    <a href="/registrationCorp" class="corp" style={{display:"none"}}>회원가입</a>
                                </p>
                            </div>
                        </form>
                    </div>
                </content>
            </div >
            <Footer />
        </>
    )
}

export default Login;